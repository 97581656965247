var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "data-cy": "info-container" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "5", lg: "5", md: "5", sm: "12" } },
            [
              _c("v-card-subtitle", [_vm._v(" Load #")]),
              _c("v-card-title", [
                _vm._v(" " + _vm._s(_vm.shipmentDetail.id) + " "),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "7", lg: "7", md: "7", sm: "12" } },
            [
              _c("v-card-subtitle", [_vm._v(" Customer")]),
              _c("v-card-title", [
                _vm._v(" " + _vm._s(_vm.shipmentDetail.customer.name) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _vm.mapType == _vm.mapTypes.googleMap
            ? [
                _c("GmapMap", {
                  ref: "mapRef",
                  staticStyle: { height: "350px", "border-radius": "4px" },
                  attrs: {
                    id: "map",
                    center: { lat: 37.77, lng: -122.447 },
                    zoom: 9,
                    "map-type-id": "terrain",
                  },
                }),
              ]
            : _vm.mapType == _vm.mapTypes.hereMap
            ? [
                _c("here-map", {
                  attrs: {
                    center: { lat: 37.77, lng: -122.447 },
                    destination: _vm.destination,
                    origin: _vm.origin,
                    "way-points": _vm.loadStops,
                    height: "350px",
                    width: "100%",
                  },
                }),
              ]
            : _vm._e(),
          _c("v-row", { staticClass: "pa-2 pt-8" }, [
            _c(
              "div",
              { staticClass: "col-md-5 pa-0 text-center" },
              [
                _c("p", { staticClass: "mb-1 grey--text text--darken-1" }, [
                  _vm._v("Pickup date"),
                ]),
                _c(
                  "v-card-text",
                  { staticClass: "cargo-name pa-0 grey--text text--darken-4" },
                  [
                    _c("render-date", {
                      attrs: { date: _vm.shipmentDetail.pickupDate },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-2 pa-0 text-center" },
              [
                _c("v-icon", { staticClass: "pt-4" }, [
                  _vm._v(" mdi-arrow-right"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-5 pa-0 text-center" },
              [
                _c("p", { staticClass: "mb-1 grey--text text--darken-1" }, [
                  _vm._v("Delivery date"),
                ]),
                _c(
                  "v-card-text",
                  { staticClass: "cargo-name pa-0 grey--text text--darken-4" },
                  [
                    _c("render-date", {
                      attrs: { date: _vm.shipmentDetail.deliveryDate },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-row", { staticClass: "pa-2" }, [
            _c(
              "div",
              { staticClass: "col-md-4 text-center" },
              [
                _c("p", { staticClass: "mb-1 grey--text text--darken-1" }, [
                  _vm._v("Equipment"),
                ]),
                _c(
                  "v-card-text",
                  {
                    staticClass: "text--regular pa-0 grey--text text--darken-4",
                  },
                  [_vm._v(" " + _vm._s(_vm.shipmentDetail.equipmentType) + " ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4 text-center" },
              [
                _c("p", { staticClass: "mb-1 grey--text text--darken-1" }, [
                  _vm._v("Agent"),
                ]),
                _c("v-card-text", {
                  staticClass: "text--regular pa-0 grey--text text--darken-4",
                  attrs: {
                    value:
                      _vm.shipmentDetail.agent && _vm.shipmentDetail.agent.name,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4 text-center" },
              [
                _c("p", { staticClass: "mb-1 grey--text text--darken-1" }, [
                  _vm._v("Order Type"),
                ]),
                _c("v-card-text", {
                  staticClass: "text--regular pa-0 grey--text text--darken-4",
                  attrs: {
                    value:
                      _vm.shipmentDetail.orderType &&
                      _vm.shipmentDetail.orderType.name,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4 text-center" },
              [
                _c("p", { staticClass: "mb-1 grey--text text--darken-1" }, [
                  _vm._v("PO Number"),
                ]),
                _c(
                  "v-card-text",
                  {
                    staticClass: "text--regular pa-0 grey--text text--darken-4",
                  },
                  [
                    _c("tooltip", {
                      attrs: { "tooltip-content": _vm.shipmentDetail.poNumber },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4 text-center" },
              [
                _c("p", { staticClass: "mb-1 grey--text text--darken-1" }, [
                  _vm._v("PU Number"),
                ]),
                _c(
                  "v-card-text",
                  {
                    staticClass: "text--regular pa-0 grey--text text--darken-4",
                  },
                  [
                    _c("tooltip", {
                      attrs: { "tooltip-content": _vm.shipmentDetail.puNumber },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4 text-center" },
              [
                _c("p", { staticClass: "mb-1 grey--text text--darken-1" }, [
                  _vm._v("Ship. Pick. Num."),
                ]),
                _c(
                  "v-card-text",
                  {
                    staticClass: "text--regular pa-0 grey--text text--darken-4",
                  },
                  [
                    _c("tooltip", {
                      attrs: {
                        "tooltip-content": _vm.shipmentDetail.shipperPickupNum,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }